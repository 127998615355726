import React, {useEffect, useState, useRef} from 'react';
import Web3 from 'web3';
import { useHistory } from "react-router-dom";

import Loader from "../../components/Loader";
import ItemAccount from "../../components/ItemAccount";

import LogoutLink from '../../components/LogoutLink';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Accounts.module.css';

function Accounts(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);

    const [network_endpoint_eth, setNetworkEndpointEth] = useState("");
    const [network_endpoint_bnb, setNetworkEndpointBnb] = useState("");

    useEffect(()=>{
        getData();
        
        return ()=>{
            //console.log("unmount");
        }
    }, []);

    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/accounts.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then(async (json) => {    
                // console.log(json);

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "error"){
                    return;
                }

                //set accounts
                let web3Eth = new Web3(new Web3.providers.WebsocketProvider(json.network_endpoint_eth)); 
                let web3Bnb = new Web3(new Web3.providers.WebsocketProvider(json.network_endpoint_bnb)); 

                let accounts = [];
                for(let i = 0; i < json.accounts.length; i++){
                    let account = json.accounts[i];

                    let eth_usd_price = "0.0";
                    let balance_eth = "0.0";
                    let balance_weth = "0.0";

                    if(account.chain === "eth"){
                        eth_usd_price = json.eth_usd_price;
                        balance_eth = await getAccountBalance(web3Eth, account.address);
                        balance_weth = await getTokenBalance(web3Eth, account.address, Constants.WETH_ADDRESS[account.chain]);
                    }

                    if(account.chain === "bnb"){
                        eth_usd_price = json.bnb_usd_price;
                        balance_eth = await getAccountBalance(web3Bnb, account.address);
                        balance_weth = await getTokenBalance(web3Bnb, account.address, Constants.WETH_ADDRESS[account.chain]);
                    }

                    accounts.push({
                        id: account.id,
                        address: account.address,
                        key: account.key,
                        chain: account.chain,
                        default: parseInt(account.default),
                        eth_usd_price: parseFloat(eth_usd_price),
                        balance_eth: parseFloat(balance_eth),
                        balance_weth: parseFloat(balance_weth),
                    });
                }

                setAccounts(accounts);
                setNetworkEndpointEth(json.network_endpoint_eth);
                setNetworkEndpointBnb(json.network_endpoint_bnb);
 
                //do web3 operations

                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    async function getAccountBalance(web3, accountAddress) {
        let balance = "0.0";
    
        try{
            balance = await web3.eth.getBalance(accountAddress);
            balance = web3.utils.fromWei(balance, 'ether');
        }catch(error){
            // console.log(error, "function getAccountBalance");	
        }
    
        return balance;
    }

    async function getTokenBalance(web3, accountAddress, tokenAddress) {
        let balance = "0.0";
    
        try{
            let token_contract = new web3.eth.Contract(Constants.TOKEN_ABI, tokenAddress);
            balance = await token_contract.methods.balanceOf(accountAddress).call()
            balance = web3.utils.fromWei(balance, 'ether')
        }catch(error){
            // console.log(error, "function getTokenBalance");	
        }
    
        return balance;
    }

    async function generateNewAccount(){

        let account = null;

        try{
            let endpoint = network_endpoint_bnb;
            let web3 = new Web3(new Web3.providers.WebsocketProvider(endpoint)); 
            account = web3.eth.accounts.create();
        }catch(error){
            //console.log(error);	
        }

        if(account == null){
            alert("Account creation failed !");
            return;
        }

        if(account.address){
            //proceed
        }else{
            alert("Account creation failed !");
            return;
        }


        let body = new FormData();
        body.append("chain", "bnb");
        body.append("address", account.address);
        body.append("key", account.privateKey);

        let options = {
            method: 'POST',
            body: body
        }
  
        let url = Constants.API_URL+"/api/accountAdd.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "ok"){
                    getData();
                }else{
                    alert("Error");
                }
                
            })
            .catch((error) => {
                // console.log(error);
                alert("Error");
            });
    }

    async function onSetDefault(chain, address){

        let body = new FormData();
        body.append("chain", chain);
        body.append("address", address);

        let options = {
            method: 'POST',
            body: body
        }
  
        let url = Constants.API_URL+"/api/accountDefault.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "ok"){
                    getData();
                }else{
                    alert("Error");
                }
                
            })
            .catch((error) => {
                // console.log(error);
                alert("Error");
            });
    }

    function goToLogin(){
        history.push("/login");
    }

    if(loading){
        return (<Loader />);
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>

                <LogoutLink />

                <div className={styles.title}>
                    Accounts 
                    <span className={styles.actionBtn} style={{marginLeft: 10}} onClick={generateNewAccount}>Generate New</span> 
                </div>

                {accounts.map((row, index) => {
                    return (
                        <ItemAccount 
                            key={row.id}  
                            nr_crt={index+1}
                            address={row.address} 
                            private_key={row.key} 
                            chain={row.chain} 
                            default={row.default} 
                            eth_usd_price={row.eth_usd_price} 
                            balance_eth={row.balance_eth} 
                            balance_weth={row.balance_weth} 
                            onSetDefault={onSetDefault}
                        />
                    )
                })}

            </div>
        </div>
    );
}


export default Accounts;