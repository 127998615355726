import React, {useEffect, useState, useRef} from 'react';
import Web3 from 'web3';
import { useHistory } from "react-router-dom";

import Loader from "../../components/Loader";
import ItemToken from "../../components/ItemToken";

import LogoutLink from '../../components/LogoutLink';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Home.module.css';


function Home() {

    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [totalBalance, setTotalBalance] = useState("0.0");
    const [accountBalance, setAccountBalance] = useState("0.0");
    const [bnbUsdPrice, setBnbUsdPrice] = useState("0.0");
    const [tokens, setTokens] = useState([]);
    const [systemStatus, setSystemStatus] = useState("");
    const [logs, setLogs] = useState([]);

    const web3 = useRef(null);

    useEffect(()=>{
        loadWeb3("bnb");
        getData();
        getLogs();

        let poolLogs = setInterval(()=>{
            getLogs();
        }, 10000);

        return ()=>{
            //console.log("unmount");
            clearInterval(poolLogs);
        }
    }, []);

    function loadWeb3(chain){
        console.log("loadWeb3("+chain+")");
        web3.current = new Web3(new Web3.providers.WebsocketProvider(Constants.NETWORK_ENDPOINT[chain], {
            timeout: 60000, // ms
        
            clientConfig: {
              // Useful if requests are large
              maxReceivedFrameSize: 100000000,   // bytes - default: 1MiB
              maxReceivedMessageSize: 100000000, // bytes - default: 8MiB
        
              // Useful to keep a connection alive
              keepalive: true,
              keepaliveInterval: 60000 // ms
            },
        
            // Enable auto reconnection
            reconnect: {
                auto: true,
                delay: 5000, // ms
                maxAttempts: 5000,
                onTimeout: false
            }
        })); 
    }

    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/home.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    
                // console.log(json);

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "error"){
                    return;
                }

                let totalBalance = 0;

                //set account balance
                setAccountBalance(json.account_balance);
                setBnbUsdPrice(json.bnb_usd_price);

                totalBalance += parseFloat(json.account_balance);

                //set tokens
                let tokens = [];
                for(let i = 0; i < json.tokens.length; i++){
                    let token = json.tokens[i];

                    tokens.push({
                        symbol: token.token_symbol,
                        name: token.token_name,
                        address: token.token_address,
                        balance: token.balance,
                        balance_bnb: token.balance_native,
                        bnb_usd_price: json.bnb_usd_price,
                    });

                    totalBalance += parseFloat(token.balance_native);
                }
                setTokens(tokens);
                setTotalBalance(totalBalance.toString());

                //set system status
                setSystemStatus(json.system_status);

                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function restartScript(){
        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/restartScript.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {     

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }


    function stopScript(){
        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/stopScript.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {       
                
                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }

    async function getTokenName(tokenAddress) {
        let name = "";
    
        try{
            let token_contract = new web3.current.eth.Contract(Constants.TOKEN_ABI, tokenAddress);
            name = await token_contract.methods.name().call()
        }catch(error){
            console.log(error, "function getTokenName");	
        }
    
        return name;
    }
    
    async function getTokenSymbol(tokenAddress) {
        let symbol = "";
    
        try{
            let token_contract = new web3.current.eth.Contract(Constants.TOKEN_ABI, tokenAddress);
            symbol = await token_contract.methods.symbol().call()
        }catch(error){
            console.log(error, "function getTokenSymbol");	
        }
    
        return symbol;
    }

    async function addToken(){
        let lcToken = prompt("Enter token address");
        if (lcToken === null) {
            return;
        }

        lcToken = lcToken.trim();
        if(lcToken === ""){
            alert("Error");
            return;
        }

        if(!web3.current.utils.isAddress(lcToken)){
            alert("Invalid token address");
            return;
        }

        lcToken = web3.current.utils.toChecksumAddress(lcToken);
        let lcTokenName = await getTokenName(lcToken);
        let lcTokenSymbol = await getTokenSymbol(lcToken);

        let body = new FormData();
        body.append("token_name", lcTokenName);
        body.append("token_symbol", lcTokenSymbol);
        body.append("token_address", lcToken);

        let options = {
            method: 'POST',
            body: body
        }
  
        let url = Constants.API_URL+"/api/tokenAdd.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "ok"){
                    getData();
                }else{
                    alert("Error");
                }
                
            })
            .catch((error) => {
                // console.log(error);
                alert("Error");
            });

    }

    function deactivateToken(symbol, name, address){
        let r = window.confirm("Deactivate token "+name+" ("+symbol+") ?");
        if(r !== true) {
            return; 
        }

        let body = new FormData();
        body.append("token_address", address);

        let options = {
            method: 'POST',
            body: body
        }
  
        let url = Constants.API_URL+"/api/tokenDeactivate.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "ok"){
                    getData();
                }else{
                    alert("Error");
                }
                
            })
            .catch((error) => {
                // console.log(error);
                alert("Error");
            });

    }

    function getLogs(){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/logsGet.php";
  
        Fetch.request(url, options, 9000)
            .then((response) => response.json())
            .then((json) => {    

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "error"){
                    return;
                }

                setLogs(json.logs);
            })
            .catch((error) => {
                // console.log(error);
            });

    }

    function clearLogs(){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/logsClear.php";
  
        Fetch.request(url, options, 9000)
            .then((response) => response.json())
            .then((json) => {    

                if(json.logged_in === "0"){
                    goToLogin();
                    return;
                }

                if(json.status === "error"){
                    return;
                }

                getLogs();
            })
            .catch((error) => {
                // console.log(error);
            });

    }

    function goToLogin(){
        history.push("/login");
    }

    if(loading){
        return (<Loader />);
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>

                <LogoutLink />

                <div className={styles.title}>Total balance</div>
                <div className={styles.accountBalance}>
                    {Util.formatNumber(parseFloat(totalBalance)*parseFloat(bnbUsdPrice), 2, 2)} $
                </div>

                <div className={styles.title}>BNB balance</div>
                <div className={styles.accountBalance}>
                    {Util.formatNumber(parseFloat(accountBalance), 0, 8)} BNB = {Util.formatNumber(parseFloat(accountBalance)*parseFloat(bnbUsdPrice), 2, 2)} $
                </div>

                <div className={styles.title}>
                    Tokens 
                    <span className={styles.actionBtn} style={{marginLeft: 10}} onClick={addToken}>Add</span> 
                </div>
                <div>
                    {tokens.map((row, index) => {
                        return (
                            <ItemToken 
                                key={row.address}  
                                symbol={row.symbol} 
                                name={row.name} 
                                address={row.address} 
                                balance={row.balance} 
                                balance_bnb={row.balance_bnb} 
                                bnb_usd_price={row.bnb_usd_price} 
                                onDoubleClick={()=>{deactivateToken(row.symbol, row.name, row.address)}}
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>
                    System is {systemStatus} <br/>
                    <span className={styles.actionBtn} onClick={restartScript}>Restart</span> 
                    <span className={styles.actionBtn} onClick={stopScript}>Stop</span> 
                </div>


                <div className={styles.title}>
                    Logs 
                    <span className={styles.actionBtn} style={{marginLeft: 10}} onClick={clearLogs}>Clear</span> 
                </div>
                <div className={styles.logsContent}>
                    {logs.map((row, index) => {
                        return (
                            <div key={index}>{row}</div>
                        )
                    })}
                </div>

            </div>
        </div>
    );
}

export default Home;


