module.exports = {
    formatNumber(value, min_decimals = 2, max_decimals = 2) {

        // if(value === 0){
        //     return value.toLocaleString('ja-JP', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        // }

        return value.toLocaleString('ja-JP', {minimumFractionDigits: min_decimals, maximumFractionDigits: max_decimals});

    },

    mysqlUtcDatetimeStringToDate(utcDateTimeString) {
        if(utcDateTimeString === "0000-00-00 00:00:00"){
            return null;
        }

        if(utcDateTimeString.indexOf("T") !== -1 && utcDateTimeString.indexOf(".") !== -1){
            // console.log(utcDateTimeString);
            // console.log(new Date(utcDateTimeString));

            return new Date(utcDateTimeString);
        }

        utcDateTimeString = utcDateTimeString.trim();
        utcDateTimeString = utcDateTimeString.replace(" ", "T");
        utcDateTimeString = utcDateTimeString+".000Z";

        // console.log(utcDateTimeString);
        // console.log(new Date(utcDateTimeString));

        return new Date(utcDateTimeString);
    },

    dateToMysqlUtcDatetimeString(date) {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    },

    getTimeFormated(date){
        //mm:ss:nn

        let minutes = date.getMinutes();
        if(minutes < 10){
            minutes = "0"+minutes;
        }

        let seconds = date.getSeconds();
        if(seconds < 10){
            seconds = "0"+seconds;
        }

        let ms = date.getMilliseconds();
        if(ms < 10){
            ms = "00"+ms;
        }else if(ms < 100){
            ms = "0"+ms;
        }

        return minutes+":"+seconds+":"+ms

    },

    copyToClipboard(value){

        // Create a "hidden" input
        var aux = document.createElement("input");

        // Assign it the value of the specified element
        aux.setAttribute("value", value);

        // Append it to the body
        document.body.appendChild(aux);

        // Highlight its content
        aux.select();

        // Copy the highlighted text
        document.execCommand("copy");

        // Remove it from the body
        document.body.removeChild(aux);
        
    }
}