import React, {useEffect, useState, useRef} from 'react';
import Web3 from 'web3';

import Loader from "../../components/Loader";
import ItemLog from "../../components/ItemLog";

import LogoutLink from '../../components/LogoutLink';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Explore.module.css';

function Explore() {

    const [loading, setLoading] = useState(true);

    const [tokenName, setTokenName] = useState("");
    const [tokenSymbol, setTokenSymbol] = useState("");
    const [logging, setLogging] = useState(false);

    const [updateCount, setUpdateCount] = useState(0);
    const updateCountReal = useRef(0);

    const web3 = useRef(null);
    const web3LogsListener = useRef(null);
    const chain = useRef("bnb");
    const tokenAddress = useRef("");
    const logsArr = useRef([]);
    const logsArrHash = useRef([]);
    const baseTokenPrice = useRef({
        eth: "2500",
        bnb: "300",
    });


    useEffect(()=>{
        loadWeb3("bnb");

        // getBaseTokenPrice();
        // let poolBaseTokenPrice = setInterval(()=>{
        //     getBaseTokenPrice();
        // }, 10000);

        setTimeout(()=>{
            setLoading(false); 
        }, 1000);

        return ()=>{
            //console.log("unmount");
            stopLogging();
            // clearInterval(poolBaseTokenPrice);
        }
    }, []);

    function loadWeb3(chain){
        console.log("loadWeb3("+chain+")");
        web3.current = new Web3(new Web3.providers.WebsocketProvider(Constants.NETWORK_ENDPOINT[chain], {
            timeout: 60000, // ms
        
            clientConfig: {
              // Useful if requests are large
              maxReceivedFrameSize: 100000000,   // bytes - default: 1MiB
              maxReceivedMessageSize: 100000000, // bytes - default: 8MiB
        
              // Useful to keep a connection alive
              keepalive: true,
              keepaliveInterval: 60000 // ms
            },
        
            // Enable auto reconnection
            reconnect: {
                auto: true,
                delay: 5000, // ms
                maxAttempts: 5000,
                onTimeout: false
            }
        })); 
    }

    function startLogging(){

        let addressesToListen = [
            tokenAddress.current
        ];
    
        if(addressesToListen.length === 0){
            return;
        }
        
        let options = {
            address: addressesToListen, 
        };

        web3LogsListener.current = web3.current.eth.subscribe('logs', options, async function(error, result){
            if (error){
                console.log(error);
                return;
            }

            try{

                let address = result.address;
                let transactionHash = result.transactionHash;
                let wsArriveDate = new Date();

                let txObject = await web3.current.eth.getTransaction(result.transactionHash);
                if(txObject === null){
                    return;
                }

                if(logsArrHash.current.indexOf(transactionHash) !== -1){
                    return;
                }

                logsArrHash.current.push(transactionHash);

                let blockNumber = result.blockNumber.toString();
                let gasLimit = txObject.gas.toString();
                let gasPrice = txObject.gasPrice.toString();
                let nonce = txObject.nonce.toString();
                let input = txObject.input.substring(10);
                let from = txObject.from;

                let tokens_symbol = "";
                let tokens = "0.0";
                let for_tokens = "0.0";
                let for_tokens_symbol = "";

                let functionId = txObject.input.substring(0,10);
                let functionName = "unknown";
                if(FunctionsIds[functionId]){
                    functionName = FunctionsIds[functionId].name;

                    let inputObject = null;
                    if(FunctionsIds[functionId].inputFormat !== null){
                        inputObject = web3.current.eth.abi.decodeLog(FunctionsIds[functionId].inputFormat, input);
                    }


                    if(functionName === "addLiquidityETH"){
                        tokens_symbol = tokenSymbol;
                        tokens = web3.current.utils.fromWei(inputObject.amountTokenMin, 'ether'); 
                        for_tokens = web3.current.utils.fromWei(inputObject.amountETHMin, 'ether');
                        for_tokens_symbol = chain.current.toUpperCase();
                    }

                    if(functionName === "addLiquidity"){
                        tokens_symbol = tokenSymbol;
                        tokens = web3.current.utils.fromWei(inputObject.amountAMin, 'ether'); 
                        for_tokens = web3.current.utils.fromWei(inputObject.amountBMin, 'ether');
                        for_tokens_symbol = await getTokenSymbol(inputObject.tokenB);
                    }


                    if(functionName === "swapExactETHForTokens" || functionName === "swapExactETHForTokensSupportingFeeOnTransferTokens"){
                        tokens_symbol = tokenSymbol;
                        tokens = web3.current.utils.fromWei(inputObject.amountOutMin, 'ether'); 
                        for_tokens = web3.current.utils.fromWei(txObject.value, 'ether'); 
                        for_tokens_symbol = chain.current.toUpperCase();
                    }

                    if(functionName === "swapExactTokensForETH" || functionName === "swapExactTokensForETHSupportingFeeOnTransferTokens"){
                        tokens_symbol = tokenSymbol;
                        tokens = web3.current.utils.fromWei(inputObject.amountIn, 'ether');
                        for_tokens = web3.current.utils.fromWei(inputObject.amountOutMin, 'ether'); 
                        for_tokens_symbol = chain.current.toUpperCase();
                    }

                    if(functionName === "swapExactTokensForTokens" || functionName === "swapExactTokensForTokensSupportingFeeOnTransferTokens"){
                        tokens_symbol = await getTokenSymbol(inputObject.path[0]);
                        tokens = web3.current.utils.fromWei(inputObject.amountIn, 'ether');
                        for_tokens = web3.current.utils.fromWei(inputObject.amountOutMin, 'ether'); 
                        for_tokens_symbol = await getTokenSymbol(inputObject.path[inputObject.path.length-1]);
                    }



                    if(functionName === "swapETHForExactTokens"){
                        tokens_symbol = chain.current.toUpperCase();
                        tokens = web3.current.utils.fromWei(txObject.value, 'ether'); 
                        for_tokens = web3.current.utils.fromWei(inputObject.amountOut, 'ether'); 
                        for_tokens_symbol = await getTokenSymbol(inputObject.path[inputObject.path.length-1]);
                    }

                    if(functionName === "swapTokensForExactETH"){
                        tokens_symbol = await getTokenSymbol(inputObject.path[0]);
                        tokens = web3.current.utils.fromWei(inputObject.amountInMax, 'ether'); 
                        for_tokens = web3.current.utils.fromWei(inputObject.amountOut, 'ether'); 
                        for_tokens_symbol = chain.current.toUpperCase();
                    }

                    if(functionName === "swapTokensForExactTokens"){
                        tokens_symbol = await getTokenSymbol(inputObject.path[0]);
                        tokens = web3.current.utils.fromWei(inputObject.amountInMax, 'ether');
                        for_tokens = web3.current.utils.fromWei(inputObject.amountOut, 'ether'); 
                        for_tokens_symbol = await getTokenSymbol(inputObject.path[inputObject.path.length-1]);
                    }

                }

                let block = await web3.current.eth.getBlock(blockNumber);
                let blockDate = new Date(block.timestamp*1000);
                let time_ws = wsArriveDate.getTime() - blockDate.getTime();
                if(time_ws > 0){
                    time_ws = "+"+time_ws;
                }

                logsArr.current.push({
                    txHash: transactionHash,
                    time: Util.getTimeFormated(wsArriveDate),
                    time_ws: time_ws.toString(),
                    function: functionName,
                    tokens_symbol: tokens_symbol,
                    tokens: tokens,
                    for_tokens: for_tokens,
                    for_tokens_symbol: for_tokens_symbol,
                    eth_price: baseTokenPrice.current[chain.current],
                    chain: chain.current,
                    gas_price: web3.current.utils.fromWei(gasPrice, 'gwei'),
                    gas_limit: gasLimit,
                    nonce: nonce,
                    block: blockNumber,
                    from: from,
                });

                updateCountReal.current = updateCountReal.current+1;
                setUpdateCount(updateCountReal.current);

                
            }catch(error){
                console.log(error, "startLogging");	
            }

        })
    }
    
    function stopLogging(){
        try{
            if(web3LogsListener.current != null){
                web3LogsListener.current.unsubscribe(function(error, success){
                    web3LogsListener.current = null;
                });
            }
        }catch(error){
            console.log(error);	
        }
    }

    async function onStart(lcToken, lcChain){
        if(logging){
            return;
        }

        if(lcToken === ""){
            alert("Invalid token address");
            return;
        }

        if(!web3.current.utils.isAddress(lcToken)){
            alert("Invalid token address");
            return;
        }

        lcToken = web3.current.utils.toChecksumAddress(lcToken);

        if(tokenAddress.current !== lcToken || chain.current !== lcChain){
            if(chain.current !== lcChain){
                loadWeb3(lcChain);
            }

            logsArr.current = [];
            logsArrHash.current = [];
            updateCountReal.current = 0;
            setUpdateCount(updateCountReal.current);

            let lcTokenName = await getTokenName(lcToken);
            let lcTokenSymbol = await getTokenSymbol(lcToken);

            setTokenName(lcTokenName);
            setTokenSymbol(lcTokenSymbol);
        }

        tokenAddress.current = lcToken;
        chain.current = lcChain;

        startLogging();
        setLogging(true);

    }

    async function onStop(){
        stopLogging();
        setLogging(false);
    }

    async function getTokenName(tokenAddress) {
        let name = "";
    
        try{
            let token_contract = new web3.current.eth.Contract(Constants.TOKEN_ABI, tokenAddress);
            name = await token_contract.methods.name().call()
        }catch(error){
            console.log(error, "function getTokenName");	
        }
    
        return name;
    }
    
    async function getTokenSymbol(tokenAddress) {
        let symbol = "";
    
        try{
            let token_contract = new web3.current.eth.Contract(Constants.TOKEN_ABI, tokenAddress);
            symbol = await token_contract.methods.symbol().call()
        }catch(error){
            console.log(error, "function getTokenSymbol");	
        }
    
        return symbol;
    }

    function getBaseTokenPrice(){

        let options = {
            method: 'GET',
        }
  
        Fetch.request("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT", options, 9000)
            .then((response) => response.json())
            .then((json) => {    
                baseTokenPrice.current.bnb = parseFloat(json.price).toString();
            })
            .catch((error) => {
                // console.log(error);
            });

        Fetch.request("https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT", options, 9000)
            .then((response) => response.json())
            .then((json) => {    
                baseTokenPrice.current.bnb = parseFloat(json.price).toString();
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    if(loading){
        return (<Loader />);
    }

    let logsTableTitle = "Logging stopped";
    if(logging){
        let chainLabel = "BSC";
        if(chain.current === "eth"){
            chainLabel = "ERC20";
        }
        logsTableTitle = tokenName+" ("+tokenSymbol+"): "+tokenAddress.current+" on "+chainLabel;
    }


    return (
        <div className={styles.root}>
            <div className={styles.container}>

                <LogoutLink />

                <div className={styles.title}>Token input</div>
                <InputToken
                    onStart={onStart}
                    onStop={onStop}
                />

                <table style={{width: "1600px", marginTop: "30px"}}>
                    <caption>
                        {logsTableTitle}
                    </caption>
                    <thead>
                        <tr>
                            <th className={styles.col1}>Time Ws/Tx</th>
                            <th className={styles.col2}>Function</th>
                            <th className={styles.col3}>Tokens</th>
                            <th className={styles.col4}>For Tokens</th>
                            {/* <th className={styles.col5}>USD</th>
                            <th className={styles.col6}>Price</th> */}
                            <th className={styles.col7}>Gas Price</th>
                            <th className={styles.col8}>Gas Limit</th>
                            <th className={styles.col9}>Nonce</th>
                            <th className={styles.col10}>Block</th>
                            <th className={styles.col11}>From</th>
                            <th className={styles.col12}>Tx</th>
                        </tr>
                    </thead>
                    <tbody>
                    {/* txHash: transactionHash,
                    time: Util.getTimeFormated(new Date()),
                    function: functionName,
                    tokens: "0.0",
                    for_tokens: "0.0",
                    eth_price: baseTokenPrice.current[chain.current],
                    chain: chain.current,
                    gas_price: web3.current.utils.fromWei(gasPrice, 'gwei'),
                    gas_limit: gasLimit,
                    nonce: nonce,
                    block: blockNumber,
                    from: from, */}
                        {logsArr.current.map((row, index) => {
                            return (
                                <ItemLog 
                                    key={row.txHash}  
                                    txHash={row.txHash} 
                                    time={row.time} 
                                    time_ws={row.time_ws} 
                                    function={row.function} 
                                    tokens_symbol={row.tokens_symbol} 
                                    tokens={row.tokens} 
                                    for_tokens={row.for_tokens} 
                                    for_tokens_symbol={row.for_tokens_symbol} 
                                    eth_price={row.eth_price} 
                                    chain={row.chain} 
                                    gas_price={row.gas_price} 
                                    gas_limit={row.gas_limit} 
                                    nonce={row.nonce} 
                                    block={row.block} 
                                    from={row.from}
                                    accountAddress={Constants.ACCOUNT_ADDRESS[chain.current]}
                                />
                            )
                        }).reverse()}
                    </tbody>
                </table>

            </div>
        </div>
    );
}

function InputToken(props){
    const [valueToken, setValueToken] = useState("");
    const [valueChain, setValueChain] = useState("bnb");

    function onStart(){
        if(props.onStart){
            props.onStart(valueToken.trim(), valueChain);
        }
    }

    function onStop(){
        if(props.onStop){
            props.onStop();
        }
    }

    return (
        <div className={styles.inputToken}>
            <input type="text" placeholder="Token" value={valueToken} onChange={(e)=>{setValueToken(e.target.value)}} />
            <select value={valueChain} onChange={(e)=>{setValueChain(e.target.value)}}>
                <option value="bnb">BSC</option>
                <option value="eth">ERC20</option>
            </select>
            <span className={styles.actionBtn} style={{marginLeft: 10}} onClick={onStart}>Start</span> 
            <span className={styles.actionBtn} style={{marginLeft: 0}} onClick={onStop}>Stop</span> 
        </div>
    );
}

const FunctionsIds = {
    "0x095ea7b3": {
        name: "approve",
        inputFormat: [{"internalType":"address","name":"spender","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],
    },

    "0xa9059cbb": {
        name: "transfer",
        inputFormat: [{"internalType":"address","name":"recipient","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],
    },

    "0x23b872dd": {
        name: "transferFrom",
        inputFormat: [{"internalType":"address","name":"sender","type":"address"},{"internalType":"address","name":"recipient","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],
    },

    "0x627dd56a": {
        name: "swap",
        inputFormat: [{"internalType":"bytes","name":"data","type":"bytes"}],
    },

    "0x23c38fa3": {
        name: "mixSwap",
        inputFormat: null,
    },

    "0x43cf7cb7": {
        name: "launch",
        inputFormat: null,
    },

    "0xa2e62045": {
        name: "update",
        inputFormat: null,
    },

    "0xd72ef771": {
        name: "work",
        inputFormat: null,
    },

    "0x3df02124": {
        name: "exchange",
        inputFormat: [{"internalType":"int128","name":"i","type":"int128"}, {"internalType":"int128","name":"j","type":"int128"}, {"internalType":"uint256","name":"dx","type":"uint256"}, {"internalType":"uint256","name":"min_dy","type":"uint256"}],
    },

    "0xa6417ed6": {
        name: "exchange_underlying",
        inputFormat: [{"internalType":"int128","name":"i","type":"int128"}, {"internalType":"int128","name":"j","type":"int128"}, {"internalType":"uint256","name":"dx","type":"uint256"}, {"internalType":"uint256","name":"min_dy","type":"uint256"}],
    },

    "0xb6b55f25": {
        name: "deposit",
        inputFormat: [{"internalType":"uint256","name":"_amount","type":"uint256"}],
    },

    "0xe2bbb158": {
        name: "deposit",
        inputFormat: [{"internalType":"uint256","name":"_pid","type":"uint256"}, {"internalType":"uint256","name":"_amount","type":"uint256"}],
    },

    "0x1c4009f9": {
        name: "zapInToken",
        inputFormat: [{"internalType":"address","name":"_from","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"},{"internalType":"address","name":"_to","type":"address"}],
    },








    "0x2195995c": {
        name: "removeLiquidityWithPermit",
        inputFormat: null,
    },





    "0xf305d719": {
        name: "addLiquidityETH",
        inputFormat: [{"internalType":"address","name":"token","type":"address"},{"internalType":"uint256","name":"amountTokenDesired","type":"uint256"},{"internalType":"uint256","name":"amountTokenMin","type":"uint256"},{"internalType":"uint256","name":"amountETHMin","type":"uint256"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0xe8e33700": {
        name: "addLiquidity",
        inputFormat: [{"internalType":"address","name":"tokenA","type":"address"},{"internalType":"address","name":"tokenB","type":"address"},{"internalType":"uint256","name":"amountADesired","type":"uint256"},{"internalType":"uint256","name":"amountBDesired","type":"uint256"},{"internalType":"uint256","name":"amountAMin","type":"uint256"},{"internalType":"uint256","name":"amountBMin","type":"uint256"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },


    "0x7ff36ab5": {
        name: "swapExactETHForTokens",
        inputFormat: [{"internalType":"uint256","name":"amountOutMin","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0x18cbafe5": {
        name: "swapExactTokensForETH",
        inputFormat: [{"internalType":"uint256","name":"amountIn","type":"uint256"},{"internalType":"uint256","name":"amountOutMin","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0x38ed1739": {
        name: "swapExactTokensForTokens",
        inputFormat: [{"internalType":"uint256","name":"amountIn","type":"uint256"},{"internalType":"uint256","name":"amountOutMin","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },


    "0xfb3bdb41": {
        name: "swapETHForExactTokens",
        inputFormat: [{"internalType":"uint256","name":"amountOut","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0x4a25d94a": {
        name: "swapTokensForExactETH",
        inputFormat: [{"internalType":"uint256","name":"amountOut","type":"uint256"},{"internalType":"uint256","name":"amountInMax","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0x8803dbee": {
        name: "swapTokensForExactTokens",
        inputFormat: [{"internalType":"uint256","name":"amountOut","type":"uint256"},{"internalType":"uint256","name":"amountInMax","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },


    "0xb6f9de95": {
        name: "swapExactETHForTokensSupportingFeeOnTransferTokens",
        inputFormat: [{"internalType":"uint256","name":"amountOutMin","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0x791ac947": {
        name: "swapExactTokensForETHSupportingFeeOnTransferTokens",
        inputFormat: [{"internalType":"uint256","name":"amountIn","type":"uint256"},{"internalType":"uint256","name":"amountOutMin","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },
    "0x5c11d795": {
        name: "swapExactTokensForTokensSupportingFeeOnTransferTokens",
        inputFormat: [{"internalType":"uint256","name":"amountIn","type":"uint256"},{"internalType":"uint256","name":"amountOutMin","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"},{"internalType":"address","name":"to","type":"address"},{"internalType":"uint256","name":"deadline","type":"uint256"}],
    },

};

export default Explore;


