import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemAccount.module.css';

ItemAccount.defaultProps = {
    nr_crt: 0,
    address: "",
    private_key: "",
    chain: "",
    default: 0,
    eth_usd_price: 0,
    balance_eth: 0,
    balance_weth: 0,
};

ItemAccount.propTypes = {
    nr_crt: PropTypes.number,
    address: PropTypes.string,
    private_key: PropTypes.string,
    chain: PropTypes.string,
    default: PropTypes.number,
    eth_usd_price: PropTypes.number,
    balance_eth: PropTypes.number,
    balance_weth: PropTypes.number,
}

function ItemAccount(props) {

    function copyAddress(){
        Util.copyToClipboard(props.address);
    }

    function copyPrivateKey(){
        Util.copyToClipboard(props.private_key);
    }

    function onSetDefault(){
        if(props.onSetDefault){
            props.onSetDefault(props.chain, props.address);
        }
    }

    let address = props.address;
    address = address.substring(0, 6)+".."+address.substring(address.length-6);

    let eth = "?";
    let weth = "?";
    let chain = "?";

    if(props.chain === "eth"){
        eth = "ETH";
        weth = "WETH";
        chain = "ERC20";
    }

    if(props.chain === "bnb"){
        eth = "BNB";
        weth = "WBNB";
        chain = "BSC";
    }

    let styleAddress = {};
    if(props.default === 1){
        styleAddress.color = "#EFB90D";
    }

    return (
        <div className={styles.root}>

            <div className={styles.head}>
                <span className={styles.nr_crt}>{props.nr_crt}</span>
                <span className={styles.address} style={styleAddress}>{address}</span>

                <span className={styles.btn} style={{marginLeft: "20px"}} title="Copy Address" onClick={copyAddress}>
                    <IcoCopy />
                </span>

                <span className={styles.btn} style={{marginLeft: "10px"}} title="Copy Private Key" onClick={copyPrivateKey}>
                    <IcoCopy />
                </span>

                <span className={styles.btn} style={{marginTop: "15px", float: "right"}} onClick={onSetDefault}>
                    <IcoCheck />
                </span>
                
            </div>

            <div className={styles.token}>
                <div className={styles.row1}>
                    <div className={styles.left}>{eth}</div>
                    <div className={styles.right}>{props.balance_eth}</div>
                </div>

                <div className={styles.row2}>
                    <div className={styles.left}>{chain}</div>
                    <div className={styles.right}>$ {Util.formatNumber((props.balance_eth * props.eth_usd_price), 2, 2)}</div>
                </div>
            </div>

            <div className={styles.token}>
                <div className={styles.row1}>
                    <div className={styles.left}>{weth}</div>
                    <div className={styles.right}>{props.balance_weth}</div>
                </div>

                <div className={styles.row2}>
                    <div className={styles.left}>{chain}</div>
                    <div className={styles.right}>$ {Util.formatNumber((props.balance_weth * props.eth_usd_price), 2, 2)}</div>
                </div>
            </div>

        </div>
    );
}

function IcoCopy(props){
    return (
        <svg style={{verticalAlign: "middle"}} width="14px" height="16px" viewBox="0 0 14 16">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
                <g id="Artboard" transform="translate(-188.000000, -172.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <path d="M198.181818,172 L189.454545,172 C188.654545,172 188,172.654545 188,173.454545 L188,183.636364 L189.454545,183.636364 L189.454545,173.454545 L198.181818,173.454545 L198.181818,172 Z M200.363636,174.909091 L192.363636,174.909091 C191.563636,174.909091 190.909091,175.563636 190.909091,176.363636 L190.909091,186.545455 C190.909091,187.345455 191.563636,188 192.363636,188 L200.363636,188 C201.163636,188 201.818182,187.345455 201.818182,186.545455 L201.818182,176.363636 C201.818182,175.563636 201.163636,174.909091 200.363636,174.909091 Z M200.363636,186.545455 L192.363636,186.545455 L192.363636,176.363636 L200.363636,176.363636 L200.363636,186.545455 Z" id="ico-copy"></path>
                </g>
            </g>
        </svg>
    );
}

function IcoCheck(props){
    return (
        <svg style={{verticalAlign: "middle"}} width="16px" height="13px" viewBox="0 0 16 13">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
                <g id="Artboard" transform="translate(-151.000000, -120.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <polygon id="Path" points="156.084707 129.62365 152.291643 125.830586 151 127.113132 156.084707 132.19784 167 121.282547 165.717453 120"></polygon>
                </g>
            </g>
        </svg>
    );
}




export default ItemAccount;