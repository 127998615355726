import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemToken.module.css';

ItemToken.defaultProps = {
    symbol: "",
    name: "",
    address: "",
    balance: "0.0",
    balance_bnb: "0.0",
    bnb_usd_price: "0.0",
};

ItemToken.propTypes = {
    symbol: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    balance: PropTypes.string,
    balance_bnb: PropTypes.string,
    bnb_usd_price: PropTypes.string,
}

function ItemToken(props) {

    let balance_usd = parseFloat(props.balance_bnb) * parseFloat(props.bnb_usd_price);
    return (
        <div {...props} className={styles.item}>
            <div className={styles.row}>
                <div className={styles.col1}>Symbol</div>
                <div className={styles.col2}>{props.symbol}</div>
            </div>

            <div className={styles.row}>
                <div className={styles.col1}>Name</div>
                <div className={styles.col2}>{props.name}</div>
            </div>

            <div className={styles.row}>
                <div className={styles.col1}>Address</div>
                <div className={styles.col2}>{props.address}</div>
            </div>

            <div className={styles.row}>
                <div className={styles.col1}>Balance</div>
                <div className={styles.col2}>{Util.formatNumber(parseFloat(props.balance), 0, 8)} ($ {Util.formatNumber(balance_usd, 2, 2)})</div>
            </div>
        </div>
    );
}


export default ItemToken;