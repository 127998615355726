import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Fetch from "./utils/Fetch";
import Constants from "./Constants";

import Loader from "./components/Loader";
import Session from './utils/Session';

import Login from './pages/Login';
import Page404 from './pages/Page404';
import Home from './pages/Home';
import Explore from './pages/Explore';
import Accounts from './pages/Accounts';



function App() {
    return (
        <Init>
            <Router>
                <Switch>

                    <PrivateRoute exact path="/">
                        <Redirect to={{pathname: "/home"}}/>
                    </PrivateRoute>

                    <PrivateRoute path="/home">
                        <Home />
                    </PrivateRoute>

                    <PrivateRoute path="/explore">
                        <Explore />
                    </PrivateRoute>

                    <PrivateRoute path="/accounts">
                        <Accounts />
                    </PrivateRoute>

                    <Route path="/login">
                        <Login />
                    </Route>
                    
                    <Route path="*">
                        <Page404 />
                    </Route>

                </Switch>
            </Router>
        </Init>
    );
}

function Init(props){
    
    const [loading, setLoading] = useState(true);

    useEffect(()=>{

        let options = {
            method: 'POST',
        }

        let url = Constants.API_URL+"/api/init.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    if(json.logged_in === "0"){
                        Session.logout();
                    }else{
                        Session.login();
                    }
                    setLoading(false);
                }  
            })
            .catch((error) => {
                // console.log(error);
            });
    }, []);

    if(loading){
        return (<Loader />);
    }

    return (props.children);

}


function PrivateRoute({ children, ...rest }) {

    const isLoggedIn = Session.isLoggedIn();
    
    return (
        <Route
            {...rest}
            render={
                ({ location })=>{
                    localStorage.last_private_route = JSON.stringify(location);
                    if(isLoggedIn){
                        return children;
                    }else{
                        return (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }}
                            />
                        )
                    }
                }
            }
        />
    );
}


export default App;
