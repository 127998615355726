import React, { useState } from 'react';
import {Redirect} from "react-router-dom";

import Session from '../../utils/Session';
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Login.module.css';

function Login() {

    const [password, setPassword] = useState("");
    const [requestStatus, setRequestStatus] = useState("");

    const lastPrivateRoute = localStorage.last_private_route || null;
    const isLoggedIn = Session.isLoggedIn();

    function submit(event){
        event.preventDefault();

        let lcPassword = password.trim();

        if(lcPassword === ""){
            return;
        }

        makeLogin(lcPassword);
    }

    function makeLogin(password){
        setRequestStatus("loading");

        let body = new FormData();
        body.append("password", password);

        let options = {
            method: 'POST',
            body: body
        }
  
        let url = Constants.API_URL+"/api/login.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);

                if(json.status === "ok"){
                    Session.login();
                }

                setRequestStatus(json.status);
                
            })
            .catch((error) => {
                // console.log(error);
                setRequestStatus("error");
            });
    }

    if(isLoggedIn){
        let to = {pathname: "/"};
        if(lastPrivateRoute != null){
            to = JSON.parse(lastPrivateRoute);
        }
        return (<Redirect to={to} />);
    }

    return (
        <div className={styles.root}>
            <div className="center-wrapper">
                <div className="center">
                    <form onSubmit={submit}>
                        <div>
                            <input 
                                name="password" 
                                type="password" 
                                placeholder="Type password" 
                                value={password} 
                                onChange={(e)=>{setPassword(e.target.value)}} />
                        </div>

                        <div>
                            <button className={styles.actionBtn} type="submit">Login</button>
                        </div>

                        <br/>
                        <span>{requestStatus}</span>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;

