import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemLog.module.css';

ItemLog.defaultProps = {
    nr_crt: 0,

    txHash: "",
    time: "0",
    time_ws: "0",
    function: "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    tokens_symbol: "",
    tokens: "0.0",
    for_tokens: "0.0",
    for_tokens_symbol: "",
    usd_value: "0.0",
    eth_price: "0.0",
    chain: "bnb",
    gas_price: "0",
    gas_limit: "0",
    nonce: "0",
    block: "0",
    from: "0x0",
    accountAddress: "",
};

ItemLog.propTypes = {
    nr_crt: PropTypes.number,

    txHash: PropTypes.string,
    time: PropTypes.string,
    time_ws: PropTypes.string,
    function: PropTypes.string,
    tokens_symbol: PropTypes.string,
    tokens: PropTypes.string,
    for_tokens: PropTypes.string,
    for_tokens_symbol: PropTypes.string,
    usd_value: PropTypes.string,
    eth_price: PropTypes.string,
    chain: PropTypes.string,
    gas_price: PropTypes.string,
    gas_limit: PropTypes.string,
    nonce: PropTypes.string,
    block: PropTypes.string,
    from: PropTypes.string,
    accountAddress: PropTypes.string,
}

function ItemLog(props) {

    // <th>Time</th>
    // <th>Function</th>
    // <th>Tokens</th>
    // <th>For Tokens</th>
    // <th>USD</th>
    // <th>Price</th>
    // <th>Gas Price Gwei</th>
    // <th>Gas Limit</th>
    // <th>Nonce</th>
    // <th>Block</th>
    // <th>From</th>

    let txLink = "https://etherscan.io/tx/";
    if(props.chain === "bnb"){
        txLink = "https://bscscan.com/tx/";
    }

    txLink = txLink+props.txHash

    let tokens = Util.formatNumber(parseFloat(props.tokens), 0, 8);
    let for_tokens = Util.formatNumber(parseFloat(props.for_tokens), 0, 8);

    let gas_price = Util.formatNumber(parseFloat(props.gas_price), 0, 2);

    let linkLabel = props.txHash.substring(0, 6);

    let functionStyle = {};
    if(props.function.startsWith("addLiquidity")){
        functionStyle.color = "rgb(35, 215, 133)";
    }

    if(props.function.startsWith("unknown")){
        functionStyle.color = "red";
    }

    let fromStyle = {};
    if(props.from === props.accountAddress){
        fromStyle.color = "rgb(35, 215, 133)";
        functionStyle = fromStyle;
    }

    return (
        <tr>
            <td className={"truncate "+styles.col1}>{props.time} {props.time_ws}</td>
            <td className={"truncate "+styles.col2} style={functionStyle}>{props.function}</td>
            <td className={"truncate "+styles.col3}>{tokens} {props.tokens_symbol}</td>
            <td className={"truncate "+styles.col4}>{for_tokens} {props.for_tokens_symbol}</td>
            {/* <td className={"truncate "+styles.col5}>${props.usd_value}</td>
            <td className={"truncate "+styles.col6}>${props.eth_price} / {props.chain.toUpperCase()}</td> */}
            <td className={"truncate "+styles.col7}>{gas_price}</td>
            <td className={"truncate "+styles.col8}>{props.gas_limit}</td>
            <td className={"truncate "+styles.col9}>{props.nonce}</td>
            <td className={"truncate "+styles.col10}>{props.block}</td>
            <td className={"truncate "+styles.col11} style={fromStyle}>{props.from}</td>
            <td className={"truncate "+styles.col12}><a href={txLink} target="_blank">{linkLabel}</a></td>
        </tr>
    );
    
}


export default ItemLog;